import React from 'react';
import Logo from '../../img/logoBlanco.png';
import { Modal, Box, Typography, Fade, Backdrop, Button } from '@mui/material';
import imgLogin from '../../img/imgNoCuenta.png';
import '../../css/notienescuenta.css';

class NoTienesCuenta extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            showModalAyuda: false,
            nombre: '',
            correo: '',
            motivo: '',
            formSent: false
         }
    }

    styleModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #2EBF70',
        borderRadius: '10px',
        boxShadow: 24,
        p: 3,
      };

    showModalAyuda = () => {
        this.setState({
            showModalAyuda: true
        })
    }

    hideModalAyuda = () => {
        this.setState({
            showModalAyuda: false
        })
    }

    enviar = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'enviarCorreo',
                nombreCliente: this.state.nombre,
                correoCliente: this.state.correo,
                motivo: this.state.motivo
            })
        };
        const response = await fetch('https://clientes.garteel.com/php/ayudaCuenta.php', requestOptions);
        const data = await response.json();
        if (data === 'Email Enviado') {
            this.setState({
                formSent: true,
                nombre: '',
                correo: '',
                motivo: '',
            })
        }else{
            alert('Hubo un error.');
        }
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() { 
        return ( 
            <>
                <div className="container-fluid notienescuenta" style={{ height: '100vh' }}>
                    <div className="row" style={{ height: '100%' }}>
                        <div className="col-md-7 p-4 p-md-5 d-flex align-items-center text-center bg-dark">
                            <div>
                                <img src={Logo} alt="imgLogo" className='mb-1 logoGarteel'/>
                                <h1 className='mb-4' style={{ color: '#2EBF70' }}>¿No tienes cuenta?</h1>
                                <div className="row">
                                    <div className="col-12 px-md-5 mb-2">
                                        <p style={{ color: 'white', textAlign: 'justify' }}>Para obtener una, debes ser parte de uno de nuestros proyectos, visita nuestra página principal,
                                        donde podrás observar los diversos proyectos disponibles para invertir. Ahí mismo sigue las instrucciones para que nos pongamos en contacto contigo
                                        y te conviertas en cliente, una vez que lo seas te mandaremos los datos de tu cuenta para ingresar.
                                        </p>
                                    </div>
                                    <div className="col-6 text-center d-flex justify-content-center">
                                        <a href="http://www.garteel.com/invertir" className='btn btn-outline-light h-100 d-flex align-items-center justify-content-center'>Ir a Garteel.com</a>
                                    </div>
                                    <div className="col-6 text-center  d-flex justify-content-center">
                                        <button className='btn btn-outline-light h-100  d-flex align-items-center justify-content-center' onClick={this.showModalAyuda}>Ayuda para clientes</button>
                                    </div>
                                </div>  
                            </div>
                        </div>
                        <div className="col-md-5 p-4 p-md-5 d-flex align-items-center justify-content-center">
                            <img src={imgLogin} alt="imgLogin" className='bg-dark' style={{ maxHeight: '100vh', width: '100%', borderRadius: '40px' }}/>
                        </div>
                    </div>
                </div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.showModalAyuda}
                    onClose={this.hideModalAyuda}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={this.state.showModalAyuda}>
                        <Box sx={this.styleModal}>
                            {this.state.formSent === false ? 
                                <>
                                    <Typography id="modal-modal-title" variant="h6" component="h6" className='text-center mb-3 pb-2' style={{ borderBottom: '2px solid #2EBF70', fontSize: '18px' }}>
                                        ¿Eres cliente y aún no tienes una cuenta o tienes problemas para acceder?
                                    </Typography>
                                    <form action="" onSubmit={this.enviar}>
                                        <label style={{ fontSize: '16px' }}>Nombre</label>
                                            <input type="text" required className='form-control mb-1' name='nombre' value={this.state.nombre} onChange={this.onChangeValue}/>
                                        <label  style={{ fontSize: '16px' }}>Correo Electrónico</label>
                                            <input type="email" className='form-control mb-3' placeholder='En caso de tener cuenta, el utilizado' name='correo' value={this.state.correo} onChange={this.onChangeValue}/>
                                        <label  style={{ fontSize: '16px' }}>Detalles sobre su problema</label>
                                            <textarea name="motivo" className='form-control mb-3' value={this.state.motivo} onChange={this.onChangeValue}/>
                                        <Button fullWidth color='success' variant='contained' type='submit'>Enviar</Button>
                                    </form>
                                </>
                                :
                                <>
                                    <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center mb-3 pb-2' style={{ borderBottom: '2px solid #2EBF70' }}>
                                        ¡Listo!
                                    </Typography>
                                    <Typography variant='p' component='p' className='text-center d-flex'>
                                        Recibimos tu información y nos pondremos en contacto contigo lo antes posible.
                                    </Typography>
                                </>
                            }
                        </Box>
                    </Fade>
                </Modal>
            </>
         );
    }
}
 
export default NoTienesCuenta;