import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Logo from '../../img/logo.png';
import imgLogin from '../../img/imgLogin.png';
import '../../css/login.css';
 
import { useAuth } from '../../context/AuthContext';
import { auth } from '../../firebase';
 
function Login () {
  const { login } = useAuth();
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
 
  const history = useHistory();
 
  const handleEmail = (e) => setEmail(e.target.value);
  const handlePasssword = (e) => setPassword(e.target.value);
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    try{
        await login(email, password)
        history.push('/')
    }catch (error){
        console.log(error.code);
        switch (error.code) {
            case 'auth/user-not-found':
                setError('No existe una cuenta asociada a este correo')
                break;
            case 'auth/wrong-password':
                setError('Contraseña incorrecta')
                break;
            case 'auth/too-many-requests':
                setError('Demasiados intentos, corrobore su contraseña')
                break;
            default:
                setError('Error interno: (' + error.code + ')')
                break;
        }
        setTimeout(() => setError(''), 3000);
    }
  }

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
        if (user){
            window.location.href = '/';
        }
    })
  }, []);

  return (
    <div className="container-fluid login" style={{ height: '100vh' }}>
        <div className="row" style={{ height: '100%' }}>
            <div className="col-md-7 p-4 p-md-5 d-flex align-items-center text-center">
                <div className=''>
                    <img src={Logo} alt='logoGarteel' className='ms-auto me-auto logoGarteel'/>
                    <h1 className="h1 mb-4" style={{ color: '#2EBF70' }}>Acceso a Clientes</h1>
                    <div className="contenedorForm ms-auto me-auto p-3" style={{ borderRadius: '10px', border: '2px solid black' }}>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-2 text-start">
                                <label htmlFor="exampleInputEmail1" className="form-label mb-0">Correo electrónico</label>
                                <input type="email" className="form-control" name='email' required id="email" autoComplete="email" aria-describedby="emailHelp" onChange={handleEmail}/>
                            </div>
                            <div className="mb-3 text-start">
                                <label htmlFor="exampleInputPassword1" className="form-label mb-0">Contraseña</label>
                                <input type="password" className="form-control" name='password' required id="password" autoComplete="current-password" onChange={handlePasssword}/>
                            </div>
                            {error && <p className='error' style={{ color: 'red' }}>{error}</p>}
                            <button type="submit" className="btn btn-outline-dark w-100">Ingresar</button>
                            <p className='mt-2 mb-0'>¿No tienes una cuenta? <Link to='/notienescuenta' style={{ color: '#2EBF70' }}>Información</Link> </p>
                        </form>
                    </div>
                </div>
            </div>
            <div className="col-md-5 p-4 p-md-5 d-flex align-items-center justify-content-center bg-dark">
                <img src={imgLogin} alt="imgLogin" style={{ maxHeight: '100vh', width: '100%', backgroundColor: 'white', borderRadius: '40px' }}/>
            </div>
        </div>
    </div>
  )
 
}
 
export default Login