import React, { Component } from 'react'

class Documentos extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    handleDescargarClick = (ruta, nombre) => {
        // Genera un timestamp único para evitar la caché
        const timestamp = new Date().getTime();

        // Agrega el timestamp como parámetro a la URL
        const url = `${ruta}?timestamp=${timestamp}`;

        const username = 'GarteelClientes'; // Reemplaza con tu nombre de usuario
        const password = 'Kmj8CL6z'; // Reemplaza con tu contraseña

        // Codifica las credenciales en Base64
        const base64Credentials = btoa(`${username}:${password}`);

        // Crea una solicitud de descarga
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);

        // Configura las credenciales en la solicitud
        xhr.setRequestHeader('Authorization', `Basic ${base64Credentials}`);

        // Maneja la respuesta de la solicitud
        xhr.onload = function () {
            if (xhr.status === 200) {
            // La descarga se realizó correctamente, puedes procesar el archivo aquí
            const blob = new Blob([xhr.response], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = nombre; // Nombre del archivo que se descargará
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            } else {
            console.error('Error al descargar el archivo. Código de estado:', xhr.status);
            }
        };

        // Envia la solicitud
        xhr.responseType = 'blob';
        xhr.send();
    };

    render() { 
        return (
                <div className="row m-0 mt-3 pb-5 documentos d-flex justify-content-center animate__animated animate__fadeInUp">
                    <div className="col-sm-9 col-md-7 col-lg-7 col-xl-6 col-xxl-5">
                        <ul className='list-group' style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}>
                            <li className='list-group-item list-group-item-action d-flex justify-content-between py-1' style={{ borderInline: 'none', borderTop: 'none' }}>
                                <div>
                                    <input className='form-check-input me-1' type="checkbox" checked readOnly/>
                                    <label htmlFor="form-check-label">Contrato</label>
                                </div>
                                <div>
                                    <button className='py-0' style={{ backgroundColor: 'transparent', border: 'none', color: 'gray' }} onClick={() => this.handleDescargarClick("/docs/proyectos/38-Contrato.pdf", "Contrato.pdf")}>Descargar</button>
                                </div>
                            </li>                                         
                        </ul>
                    </div>
                </div>
        );
    }
}
 
export default Documentos;