import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import '../../css/tarjetaFase.css';
import { CardActionArea } from '@mui/material';
import sinImagen from '../../img/sin-imagen.jpg';

class TarjetaFase extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 

        }
    }

    componentDidMount = () => {
        
    }

    barraProgreso = () => {
        var porcentaje = this.props.data.porcentaje;
        var gradient = '';
        if (porcentaje < 50) {
            gradient = 'linear-gradient(to right, #212529 ' + porcentaje + '%, white ' + porcentaje + '%)';
        }else{
            gradient = 'linear-gradient(to right, #212529 ' + porcentaje + '%, white ' + (100 - Number(porcentaje)) + '%)';
        }
        return gradient
    }

    porcentaje = () => {
        var porcentaje = this.props.data.porcentaje;
        if (porcentaje < 10) {
            if (porcentaje < 3) {
                return '3%'
            }else{
                return porcentaje + '%'
            }
        }else{
            if (Number(porcentaje) === 100) {
                return (Number(porcentaje) - 12) + '%'  
            }else{
                return (Number(porcentaje) - 7) + '%'   
            }
        }
    }

    openModalImg = (urlImg) => {
        this.props.openModalImg(urlImg);
    } 

    render() { 
        return (  
            <>
                <div className="col-lg-6 col-xl-4 col-xxl-3 tarjetaFase px-3 py-3">
                    <Card variant='outlined' style={{ borderRadius: '5px', height: '100%' }}>
                        <CardActionArea onClick={() => this.openModalImg("https://clientes.garteel.com/img/" + this.props.data.imagen)} style={{ height: '100%' }}>
                            <CardContent style={{ backgroundColor: '#212529' }}>
                                <Typography gutterBottom variant='h6' component="div" className='text-center' style={{ color: 'white', fontWeight: '200' }}>
                                    {this.props.data.titulo}
                                </Typography>
                            </CardContent>
                            {
                                this.props.data.imagen ?
                                    <CardMedia
                                        component="img"
                                        width="100"
                                        image={"https://clientes.garteel.com/img/" + this.props.data.imagen}
                                        alt="imgFase"
                                        className='imagenTarjeta'
                                    />
                                :
                                <CardMedia
                                        component="img"
                                        width="100"
                                        image={sinImagen}
                                        alt="imgFase"
                                        className='imagenTarjeta'
                                    />
                            }
                            <CardContent className='pt-0 px-0 pb-0' style={{ height: '100%' }}>
                                <div className="row p-0">
                                    <div className="col-12 p-0">
                                        <div className='' style={{ width: '100%', height: '20px', padding: '3px', background: this.barraProgreso() }}>
                                            <p className='p-0 m-0 porcentaje text-center' style={{ position: 'relative', left: this.porcentaje() }}>
                                                {this.props.data.porcentaje + '%'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row pt-2 px-3" style={{ borderTop: '1px solid black' }}>
                                    <div className="col-12">
                                        <p style={{ fontWeight: 'bold', fontSize: '12px' }} className='m-0'>Descripción</p>
                                        <p style={{ fontSize: '12px', textAlign: 'justify' }}>{this.props.data.descripcion}</p>
                                    </div>
                                </div>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </div>
            </>
        );
    }
}
 
export default TarjetaFase;