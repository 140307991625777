import React, { Component } from 'react';
import '../../css/sidebar.css';
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import { auth } from '../../firebase';
import TablaProyectosConstruccion from './TablaProyectosConstruccion';

class ProyectosConstruccion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggleSidebar: false
        }
    }
    

    toggleSidebar = () => {
        this.setState({
            toggleSidebar: !this.state.toggleSidebar
        })
    }

    componentDidMount = async () => {
        document.title = 'Inicio - Garteel Clientes';
        auth.onAuthStateChanged( async (user) => {
            if (user) {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ operacion: 'selectWhere', correo: user.email })
                };
                const response = await fetch('https://clientes.garteel.com/php/DB/inversionistas.php', requestOptions);
                const data = await response.json();
                this.setState({
                    nombre: data[0].nombre.split(" ")[0]
                })
            }
        });
    }
   
    render() { 
        return ( 
            <div className='app'>
                <Sidebar toggledSidebar={this.state.toggleSidebar} toggleSidebar={this.toggleSidebar}/>
                <main>
                    <div className="container-fluid p-0">
                        <Navbar toggleSidebar={this.toggleSidebar}/>
                        <div className='py-4 px-3'>
                            <h6 className='text-end'>Bienvenido, {this.state.nombre}.</h6>
                            <TablaProyectosConstruccion/>
                        </div>
                    </div>
                </main>
            </div>
         );
    }
}
 
export default ProyectosConstruccion;