import React, { Component } from 'react';
import { auth } from '../../firebase';
import '../../css/sidebar.css';
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import imgAyuda from '../../img/ayuda.jpg'

class Ayuda extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usuario: '',
            inputAyuda: ''
        }
    }

    componentDidMount = () => {
        document.title = 'Ayuda - Garteel Clientes';
        auth.onAuthStateChanged( async (user) => {
            if (user) {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ operacion: 'selectWhere', correo: user.email })
                };
                const response = await fetch('https://clientes.garteel.com/php/DB/inversionistas.php', requestOptions);
                const data = await response.json();
                this.setState({
                    nombre: data[0].nombre,
                    usuario: user.email
                }) 
            }
        })
    }

    enviar = async () => {
        if (this.state.inputAyuda !== '') {
            const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'enviarCorreo',
                nombreCliente: this.state.nombre,
                correoCliente: this.state.usuario,
                motivo: this.state.inputAyuda
            })
            };
            const response = await fetch('https://clientes.garteel.com/php/ayudaCuenta.php', requestOptions);
            const data = await response.json();
            if (data === 'Email Enviado') {
                this.setState({
                    inputAyuda: '',
                })
            }else{
                alert('Hubo un error.');
            }
        }else{
            alert('Primero, llene los campos necesarios.');
        }
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    toggleSidebar = () => {
        this.setState({
            toggleSidebar: !this.state.toggleSidebar
        })
    }

    render() { 
        return ( 
            <div className='app'>
                <Sidebar toggledSidebar={this.state.toggleSidebar} toggleSidebar={this.toggleSidebar}/>
                <main>
                    <div className="container-fluid p-0">
                        <Navbar toggleSidebar={this.toggleSidebar}/>
                        <div className='py-4 px-3'>
                            <h1>Ayuda</h1>
                            <hr className='mt-1'/>
                            <div className="row px-3">
                                <h2 className='text-center mb-3'>¿En qué podemos ayudarte?</h2>
                                <div className="col-xl-6 d-flex align-items-center">
                                    <div>
                                        <label>Cuéntanos qué sucede:</label>
                                        <textarea className='form-control' name="inputAyuda" id="" rows="5" cols='100' style={{ resize: 'none' }} onChange={this.onChangeValue}></textarea>
                                        <button className='btn btn-success ms-auto me-auto d-flex mt-3' onClick={this.enviar}>Enviar</button>
                                    </div>
                                </div>
                                <div className="col-xl-6 mt-5 mt-xl-0">
                                    <img src={imgAyuda} width='100%' alt="imgAyuda"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div> 
         );
    }
}
 
export default Ayuda;