import React from 'react';
import { auth } from '../../firebase';
import '../../css/tablaproyectosconstruccion.css';
import { Table } from 'react-bootstrap';
import { SpinnerInfinity } from 'spinners-react';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';

class TablaProyectosConstruccion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            usuario: '',
            proyectos: [],
            loading: true,
            porcentaje: 20
        }
    }

    componentDidMount = () => {       
        auth.onAuthStateChanged( async (user) => {
            if (user) {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ operacion: 'selectProyectosConstruccion', correo: user.email })
                };
                const response = await fetch('https://clientes.garteel.com/php/DB/inversionistas.php', requestOptions);
                console.log(response);                
                const data = await response.json();
                console.log(data);                
                this.setState({
                    usuario: user.email,
                    proyectos: data,
                    loading: false
                });
            }
        })        
    }

    numberWithCommas(x) {
        if (x === 0) {
            return '0.00';
        }else{
            x = Number(x).toFixed(2);
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    redirect = async (e) => {
        //window.location.href = `/proyecto/${e.target.id}`;
        this.props.history.push(`/proyecto/${e.target.id}`);   
    }

    barraProgreso = (porcentaje) => {
        var gradient = '';
        if (porcentaje < 50) {
            gradient = 'linear-gradient(to right, #2EBF70 ' + porcentaje + '%, white ' + porcentaje + '%)';
        }else{
            gradient = 'linear-gradient(to right, #2EBF70 ' + porcentaje + '%, white ' + (100 - Number(porcentaje)) + '%)';
        }
        return gradient
    }

    porcentaje = (porcentaje) => {
        if (porcentaje < 6) {
            return porcentaje + '%'
        }else{
            return (Number(porcentaje) - 6) + '%'   
        }
    }

    render() { 
        return ( 
            <div className='tablaProyectos'>
                <h2 className='text-center'>Proyectos</h2>
                <hr />
                    <div className='row'>
                        <div className="col-12">
                            <Table hover variant='dark' id='tablaProyectos'>
                                <thead>
                                    <tr>
                                        <th>
                                        </th>
                                        <th>
                                            Nombre del Proyecto
                                        </th>
                                        <th>
                                            Progreso
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    this.state.loading ?
                                        <tr>
                                            <td colSpan='3' className='text-center py-4' style={{ borderBottomLeftRadius: '5px' }}>
                                                <SpinnerInfinity size={100} thickness={70} speed={150} color="rgba(255, 255, 255, 1)" secondaryColor="rgba(46, 191, 112, 1)"/>
                                            </td>
                                        </tr>
                                    :
                                    this.state.proyectos[0] ?
                                        this.state.proyectos.map(e =>
                                            <tr key={e.proc_idProyectoConstruccion} id={e.proc_idProyectoConstruccion} onClick={this.redirect} style={{ cursor: 'pointer' }}>
                                                <td className='text-center' style={{ width: '10%' }} id={e.proc_idProyectoConstruccion}>
                                                    <img src={"/img/proyectos/" + e.proc_idProyectoConstruccion + '.jpg'} onError={(e) => {e.target.onerror = null; e.target.src="/img/sinImagen.jpg";}}  id={e.proc_idProyectoConstruccion} alt="imgProyectoConstruccion" style={{ maxWidth: '120px', maxHeight: '80px', borderRadius: '5px' }}/>
                                                </td>
                                                <td className='align-middle tdNombreProyecto' id={e.proc_idProyectoConstruccion}>
                                                    <div className="row" id={e.proc_idProyectoConstruccion}>
                                                        <div className="col-12" id={e.proc_idProyectoConstruccion}>
                                                            <p className='mb-0' id={e.proc_idProyectoConstruccion}>{e.nombre}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='align-middle tdProgreso' id={e.proc_idProyectoConstruccion} >
                                                    <div className="row py-0" id={e.proc_idProyectoConstruccion}>
                                                        <div className="col-12 py-0" id={e.proc_idProyectoConstruccion}>
                                                            <div className='d-md-none'>
                                                                <p>{e.progreso}%</p>
                                                            </div>
                                                            <div className='d-none d-md-block' id={e.proc_idProyectoConstruccion} style={{ width: '100%', height: '25px', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '1px', paddingRight: '1px', background: this.barraProgreso(e.progreso), borderRadius: '3px' }}>
                                                                <p className='p-0 m-0 porcentaje text-center' id={e.proc_idProyectoConstruccion} style={{ position: 'relative', left: this.porcentaje(e.progreso) }}>
                                                                    {e.progreso + '%'}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>)
                                        :
                                        <tr>
                                            <td colSpan='4' className='text-center'>
                                                No cuenta con proyectos activos
                                            </td>
                                        </tr>
                                            }
                                </tbody>
                            </Table>
                        </div>
                    </div>
            </div>
                
        );
    }
}
 
export default withRouter(TablaProyectosConstruccion);