import React from 'react';
import { auth } from '../../firebase';
import '../../css/tablaproyectos.css';
import { Table } from 'react-bootstrap';
import { SpinnerInfinity } from 'spinners-react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';

class Proyectos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            usuario: '',
            proyectos: [],
            loading: true,
        }
    }

    componentDidMount = () => {
        auth.onAuthStateChanged( async (user) => {
            if (user) {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ operacion: 'selectProyectos', correo: user.email })
                };
                const response = await fetch('https://clientes.garteel.com/php/DB/inversionistas.php', requestOptions);
                const data = await response.json();
                console.log(data);
                this.setState({
                    usuario: user.email,
                    proyectos: data,
                    loading: false
                });
            }
        })        
    }

    numberWithCommas(x) {
        if (x === 0) {
            return '0.00';
        }else{
            x = Number(x).toFixed(2);
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    redirect = async (e) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectNombreLink', idProyecto: e.target.id })
        };
        const response = await fetch('https://clientes.garteel.com/php/DB/proyectos.php', requestOptions);
        const data = await response.json();
        window.location.href = '/' + e.target.id + '-' + data;
    }

    barraProgreso = (porcentaje) => {
        var gradient = '';
        if (porcentaje < 50) {
            gradient = 'linear-gradient(to right, #2EBF70 ' + porcentaje + '%, white ' + porcentaje + '%)';
        }else{
            gradient = 'linear-gradient(to right, #2EBF70 ' + porcentaje + '%, white ' + (100 - Number(porcentaje)) + '%)';
        }
        return gradient
    }

    porcentaje = (porcentaje) => {
        if (porcentaje < 6) {
            return porcentaje + '%'
        }else{
            return (Number(porcentaje) - 6) + '%'   
        }
    }

    columnaEstado = (datos) => {
        let fecha = datos.fechaLimiteInversion;
        let pagoRendimientos = datos.pagoRendimientos;
        let diasFaltantes = this.diasFaltantes(fecha);
        let mesesTranscurridos = this.getMonthsBetween(fecha);
        let plazosTranscurridos = 0;
        if (pagoRendimientos === 'Mensual') {
            plazosTranscurridos = mesesTranscurridos/1;
            plazosTranscurridos = Math.floor(Math.abs(plazosTranscurridos));
        }
        if (pagoRendimientos === 'Trimestral') {
            plazosTranscurridos = mesesTranscurridos/3;
            plazosTranscurridos = Math.floor(Math.abs(plazosTranscurridos));
        }
        if (pagoRendimientos === 'Cuatrimestral') {
            plazosTranscurridos = mesesTranscurridos/4;
            plazosTranscurridos = Math.floor(Math.abs(plazosTranscurridos));
        }
        if (pagoRendimientos === 'Semestral') {
            plazosTranscurridos = mesesTranscurridos/6;
            plazosTranscurridos = Math.floor(Math.abs(plazosTranscurridos));
        }
        if (pagoRendimientos === 'Anual') {
            plazosTranscurridos = mesesTranscurridos/12;
            plazosTranscurridos = Math.floor(Math.abs(plazosTranscurridos));
        }
        let fechaDisplay = new Date(fecha + 'T00:00:00');
        fechaDisplay = fechaDisplay.toLocaleString('es-MX',{dateStyle: 'short'});
        if (diasFaltantes > 0) {
            return <div className="row" id={datos.idProyecto}>
                        <div className="col-12" id={datos.idProyecto}>
                            <p id={datos.idProyecto}>Fecha de inicio de proyecto: {fechaDisplay}</p>
                        </div>
                        <div className="col-12" id={datos.idProyecto}>
                            <p id={datos.idProyecto}>Faltan {diasFaltantes} día(s) para empezar a generar rendimientos.</p>
                        </div>
                    </div>
        }else{
            return <div className="row" id={datos.idProyecto}>
                        <div className="col-12" id={datos.idProyecto}>
                            <p className='mb-0' id={datos.idProyecto}>Fecha de inicio de proyecto: {fechaDisplay}</p>
                        </div>
                        <div className="col-12" id={datos.idProyecto}>
                            <p className='mb-0' id={datos.idProyecto}>Pago de rendimientos: {pagoRendimientos}</p>
                        </div>
                        <div className="col-12" id={datos.idProyecto}>
                            <p className='mb-0' id={datos.idProyecto}>Plazos cumplidos: {plazosTranscurridos}</p>
                        </div>
                        <div className="col-12" id={datos.idProyecto}>
                            <hr id={datos.idProyecto}/>
                        </div>
                        <div className="col-12" id={datos.idProyecto}>
                            <p id={datos.idProyecto}>Generado: ${this.numberWithCommas(this.montoPlazo(datos) * plazosTranscurridos)}</p>
                        </div>
                    </div>
        }
    }

    rowEstado = (datos) => {
        let fecha = datos.fechaLimiteInversion;
        let pagoRendimientos = datos.pagoRendimientos;
        let diasFaltantes = this.diasFaltantes(fecha);
        let mesesTranscurridos = this.getMonthsBetween(fecha);
        let plazosTranscurridos = 0;
        if (pagoRendimientos === 'Mensual') {
            plazosTranscurridos = mesesTranscurridos/1;
            plazosTranscurridos = Math.floor(Math.abs(plazosTranscurridos));
        }
        if (pagoRendimientos === 'Trimestral') {
            plazosTranscurridos = mesesTranscurridos/3;
            plazosTranscurridos = Math.floor(Math.abs(plazosTranscurridos));
        }
        if (pagoRendimientos === 'Cuatrimestral') {
            plazosTranscurridos = mesesTranscurridos/4;
            plazosTranscurridos = Math.floor(Math.abs(plazosTranscurridos));
        }
        if (pagoRendimientos === 'Semestral') {
            plazosTranscurridos = mesesTranscurridos/6;
            plazosTranscurridos = Math.floor(Math.abs(plazosTranscurridos));
        }
        if (pagoRendimientos === 'Anual') {
            plazosTranscurridos = mesesTranscurridos/12;
            plazosTranscurridos = Math.floor(Math.abs(plazosTranscurridos));
        }

        if (diasFaltantes > 0) {
            return <div className="row" id={datos.idProyecto}>
                        <div className="col-12" id={datos.idProyecto}>
                            <p id={datos.idProyecto}>Fecha de inicio de proyecto: {fecha}</p>
                        </div>
                        <div className="col-12" id={datos.idProyecto}>
                            <p id={datos.idProyecto}>Faltan {diasFaltantes} día(s) para empezar a generar rendimientos.</p>
                        </div>
                    </div>
        }else{
            return <div className="row" id={datos.idProyecto}>
                        <div className="col-12" id={datos.idProyecto}>
                            <p className='mb-0' id={datos.idProyecto}>Fecha de inicio de proyecto: {fecha}</p>
                        </div>
                        <div className="col-12" id={datos.idProyecto}>
                            <p className='mb-0' id={datos.idProyecto}>Pago de rendimientos: {pagoRendimientos}</p>
                        </div>
                        <div className="col-12" id={datos.idProyecto}>
                            <p className='mb-0' id={datos.idProyecto}>Plazos cumplidos: {plazosTranscurridos}</p>
                        </div>
                        <div className="col-12" id={datos.idProyecto}>
                            <hr id={datos.idProyecto}/>
                        </div>
                        <div className="col-12 text-center" id={datos.idProyecto}>
                            <p id={datos.idProyecto}>Generado: ${this.numberWithCommas(this.montoPlazo(datos) * plazosTranscurridos)}</p>
                        </div>
                    </div>
        }
    }

    diasFaltantes = (fecha) => {
        let fechaActual = new Date();
        let fechaLimite = new Date(fecha + ' 00:00');
        var one_day=1000*60*60*24;
        return Math.ceil((fechaLimite.getTime()-fechaActual.getTime())/(one_day));
    }

    getMonthsBetween(fecha,roundUpFractionalMonths){
        let date1 = new Date();
        let date2 = new Date(fecha + ' 00:00');
        //Months will be calculated between start and end dates.
        //Make sure start date is less than end date.
        //But remember if the difference should be negative.
        var startDate=date1;
        var endDate=date2;
        var inverse=false;
        if(date1>date2)
        {
            startDate=date2;
            endDate=date1;
            inverse=true;
        }

        //Calculate the differences between the start and end dates
        var yearsDifference=endDate.getFullYear()-startDate.getFullYear();
        var monthsDifference=endDate.getMonth()-startDate.getMonth();
        var daysDifference=endDate.getDate()-startDate.getDate();

        var monthCorrection=0;
        //If roundUpFractionalMonths is true, check if an extra month needs to be added from rounding up.
        //The difference is done by ceiling (round up), e.g. 3 months and 1 day will be 4 months.
        if(roundUpFractionalMonths===true && daysDifference>0)
        {
            monthCorrection=1;
        }
        //If the day difference between the 2 months is negative, the last month is not a whole month.
        else if(roundUpFractionalMonths!==true && daysDifference<0)
        {
            monthCorrection=-1;
        }

        return (inverse?-1:1)*(yearsDifference*12+monthsDifference+monthCorrection);
    }

    montoPlazo = (datos) => {
        let montoPlazo = 0;
        let rendimientosGarantizada = (datos.inversion * (datos.tirGarantizado/100));
        if (datos.pagoRendimientos === 'Mensual') {
            montoPlazo = rendimientosGarantizada/(datos.plazo*12);
        }
        if (datos.pagoRendimientos === 'Trimestral') {
            montoPlazo = rendimientosGarantizada/(datos.plazo*4);
        }
        if (datos.pagoRendimientos === 'Cuatrimestral') {
            montoPlazo = rendimientosGarantizada/(datos.plazo*3);
        }
        if (datos.pagoRendimientos === 'Semestral') {
            montoPlazo = rendimientosGarantizada/(datos.plazo*2);
        }
        if (datos.pagoRendimientos === 'Anual') {
            montoPlazo = rendimientosGarantizada/(datos.plazo*1);
        }
        return montoPlazo.toFixed(2)
    }

    render() { 
        return ( 
            <div className='tablaProyectos'>
                <h2 className='text-center'>Inversiones</h2>
                <hr />
                    <div className="row d-xl-none">
                    {
                    this.state.loading ?
                        <div className="col-12 p-5 d-flex justify-content-center" style={{ borderRadius: '5px' }}>
                            <div className='bg-dark p-4' style={{ borderRadius: '10px', width:'fit-content' }}>
                                <SpinnerInfinity size={100} thickness={70} speed={150} color="rgba(255, 255, 255, 1)" secondaryColor="rgba(46, 191, 112, 1)"/>
                            </div>
                        </div>
                    :
                    this.state.proyectos[0] ?
                        this.state.proyectos.map(e =>
                            <div className="col-lg-6 col-xl-4 px-sm-5 px-lg-0" key={e.idProyecto}>
                                <Card style={{ borderRadius: '5px', color: 'white'}} className='bg-dark' id={e.idProyecto}>
                                    <CardActionArea onClick={this.redirect} id={e.idProyecto}>
                                        <CardMedia
                                            component="img"
                                            width="100"
                                            image={"https://www.garteel.com/img/proyectos/" + e.img}
                                            alt="imgProyecto"
                                            id={e.idProyecto}
                                            style={{ height: '200px' }}
                                        />
                                        <CardContent id={e.idProyecto}>
                                            <div className="row" id={e.idProyecto}>
                                                <div className="col-12" id={e.idProyecto}>
                                                    <h5 id={e.idProyecto}>{e.nombre}</h5>
                                                </div>
                                                <div className="col-6" id={e.idProyecto}>
                                                    <p className='mb-0' id={e.idProyecto}>Inversión</p>
                                                </div>
                                                <div className="col-6" id={e.idProyecto}>
                                                    <p className='mb-0 text-end' id={e.idProyecto}>${this.numberWithCommas(e.inversion)}</p>
                                                </div>
                                                <div className="col-6" id={e.idProyecto}>
                                                    <p className='mb-0' id={e.idProyecto}>Ganancia*</p>
                                                </div>
                                                <div className="col-6" id={e.idProyecto}>
                                                    <p className='mb-0 text-end' id={e.idProyecto}>${this.numberWithCommas(e.inversion*(e.tirGarantizado/100))}</p>
                                                </div>
                                                <div className="col-6" id={e.idProyecto}>
                                                    <p className='mb-1' id={e.idProyecto}>Total*</p>
                                                </div>
                                                <div className="col-6" id={e.idProyecto}>
                                                    <p className='text-end mb-1' id={e.idProyecto}>${this.numberWithCommas(Number(e.inversion*(e.tirGarantizado/100)) + Number(e.inversion))}</p>
                                                </div>
                                                <div className="col-12 text-center" id={e.idProyecto}>
                                                    <p className='mb-1' style={{ fontSize: '10px' }} id={e.idProyecto}>* Calculado con base en el rendimiento garantizado.</p>
                                                </div>
                                                <div className="col-12">
                                                    <hr id={e.idProyecto}/>
                                                </div>
                                            </div>
                                            {this.rowEstado(e)}
                                            <div className="row py-0" id={e.idProyecto}>
                                                <div className="col-12 text-center" id={e.idProyecto}>
                                                    <h6 id={e.idProyecto}>Progreso</h6>
                                                </div>
                                                <div className="col-12 py-0" id={e.idProyecto}>
                                                    <div id={e.idProyecto} style={{ width: '100%', height: '25px', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '1px', paddingRight: '1px', background: this.barraProgreso(e.porcentaje), borderRadius: '3px' }}>
                                                        <p id={e.idProyecto} className='p-0 m-0 porcentaje text-center' style={{ position: 'relative', left: '45%' }}>
                                                            {e.porcentaje + '%'}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </div>
                            )
                        :
                            <h1>No cuenta con inversiones activas</h1>
                    }
                    </div>

                    <div className='row d-none d-xl-block'>
                        <div className="col-12">
                            <Table hover variant='dark' id='tablaProyectos'>
                                <thead>
                                    <tr>
                                        <th>
                                        </th>
                                        <th>
                                            Datos del Proyecto
                                        </th>
                                        <th>
                                            Finanzas
                                        </th>
                                        <th>
                                            Progreso
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    this.state.loading ?
                                        <tr>
                                            <td colSpan='3' className='text-center py-4' style={{ borderBottomLeftRadius: '5px' }}>
                                                <SpinnerInfinity size={100} thickness={70} speed={150} color="rgba(255, 255, 255, 1)" secondaryColor="rgba(46, 191, 112, 1)"/>
                                            </td>
                                        </tr>
                                    :
                                    this.state.proyectos[0] ?
                                        this.state.proyectos.map(e =>
                                            <tr key={e.idProyecto} id={e.idProyecto} onClick={this.redirect} style={{ cursor: 'pointer' }}>
                                                <td className='text-center' style={{ width: '10%' }} id={e.idProyecto}>
                                                    <img src={"https://www.garteel.com/img/proyectos/" + e.img} id={e.idProyecto} alt="imgProyecto" style={{ maxWidth: '120px', maxHeight: '120px', borderRadius: '5px' }}/>
                                                </td>
                                                <td className='align-middle' id={e.idProyecto} style={{ width: '25%' }}>
                                                    <div className="row" id={e.idProyecto}>
                                                        <div className="col-12" id={e.idProyecto}>
                                                            <p className='mb-0' style={{ fontSize: '18px' }} id={e.idProyecto}>{e.nombre}</p>
                                                        </div>
                                                    </div>
                                                    <div className='' style={{ width: '180px' }}>
                                                        <div className="row" id={e.idProyecto}>
                                                            <div className="col-6" id={e.idProyecto}>
                                                                <p className='mb-0' style={{ fontSize: '12px' }} id={e.idProyecto}>Inversión:</p>
                                                            </div>
                                                            <div className="col-6 text-end" id={e.idProyecto}>
                                                                <p className='mb-0' style={{ fontSize: '12px' }} id={e.idProyecto}>${this.numberWithCommas(e.inversion)}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row" id={e.idProyecto}>
                                                            <div className="col-6" id={e.idProyecto}>
                                                                <p className='mb-0' style={{ fontSize: '12px' }} id={e.idProyecto}>Ganancia:*</p>
                                                            </div>
                                                            <div className="col-6 text-end" id={e.idProyecto}>
                                                                <p className='mb-0' style={{ fontSize: '12px' }} id={e.idProyecto}>${this.numberWithCommas(e.inversion*(e.tirGarantizado/100))}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row" id={e.idProyecto}>
                                                            <div className="col-6" id={e.idProyecto}>
                                                                <p className='mb-1' style={{ fontSize: '12px' }} id={e.idProyecto}>Total:*</p>
                                                            </div>
                                                            <div className="col-6 text-end" id={e.idProyecto}>
                                                                <p className='mb-1' style={{ fontSize: '12px' }} id={e.idProyecto}>${this.numberWithCommas(Number(e.inversion*(e.tirGarantizado/100)) + Number(e.inversion))}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row" id={e.idProyecto}>
                                                            <div className="col-12" id={e.idProyecto}>
                                                                <p style={{ fontSize: '10px' }} id={e.idProyecto}>* Calculado con base en el rendimiento garantizado.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='align-middle' id={e.idProyecto} style={{ width: '35%' }}>
                                                    {this.columnaEstado(e)}
                                                </td>
                                                <td className='align-middle' id={e.idProyecto} style={{ width: '30%' }}>
                                                    <div className="row py-0" id={e.idProyecto}>
                                                        <div className="col-12 py-0" id={e.idProyecto}>
                                                            <div className='' id={e.idProyecto} style={{ width: '100%', height: '25px', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '1px', paddingRight: '1px', background: this.barraProgreso(e.porcentaje), borderRadius: '3px' }}>
                                                                <p className='p-0 m-0 porcentaje text-center' id={e.idProyecto} style={{ position: 'relative', left: this.porcentaje(e.porcentaje) }}>
                                                                    {e.porcentaje + '%'}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>)
                                        :
                                        <tr>
                                            <td colSpan='4' className='text-center'>
                                                No cuenta con inversiones activas
                                            </td>
                                        </tr>
                                            }
                                </tbody>
                            </Table>
                        </div>
                    </div>
            </div>
                
        );
    }
}
 
export default Proyectos;