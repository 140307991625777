import React from 'react';
import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarContent, SidebarFooter } from 'react-pro-sidebar';
import logo from '../img/logoBlanco.png';
import '../css/sidebar.scss';
import { Link } from 'react-router-dom';
import { auth } from '../firebase';

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            usuario: ''
        }
    }

    componentDidMount = () => {
        auth.onAuthStateChanged((user) => {
            if (user) {
            this.setState({
                usuario: user.email
            });
            }
        })
    }

    CerrarSesion = () => {
        auth.signOut();
        this.setState({
          usuario: null
        });
    }

    render() { 
        return ( 
            <ProSidebar breakPoint='lg' toggled={this.props.toggledSidebar} onToggle={this.props.toggleSidebar}>
                <SidebarHeader className='text-center py-2'>
                    <a href="/"><img src={logo} alt="logoGarteel" width='70%'/></a>
                    {this.state.usuario}
                </SidebarHeader>
                <SidebarContent>
                    <Menu iconShape="square">
                        <MenuItem>
                            Inicio
                            <Link to='/'/>
                        </MenuItem>
                        <MenuItem>
                            Inversiones
                            <Link to='/inversiones'/>
                        </MenuItem>
                        <MenuItem>
                            Mi cuenta
                            <Link to='/cuenta'/>
                        </MenuItem>
                        <MenuItem>
                            Ayuda
                            <Link to='/ayuda'/>
                        </MenuItem>
                    </Menu>
                </SidebarContent>
                <SidebarFooter className='p-2 text-center'>
                    <button onClick={this.CerrarSesion} type='button' className='btn btn-outline-light w-100'>Cerrar Sesión</button>
                </SidebarFooter>
            </ProSidebar>
         );
    }
}
 
export default Sidebar;